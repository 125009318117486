import React from "react";
import Particles from "react-particles-js";
import { particleData } from "./config/particles.js";
import { DevInfo } from "./components/DevInfo.js";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Particles params={particleData} />
      <DevInfo
        name={process.env.REACT_APP_DEV_NAME}
        title={process.env.REACT_APP_DEV_TITLE}
        link={process.env.REACT_APP_DEV_LINK}
      />
    </div>
  );
}

export default App;
