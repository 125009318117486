import React from "react";

export const DevInfo = ({ name, title, link }) => {
  return (
    <div id="dev-info" className="bold unselectable">
      <span id="dev-name">{name}</span>
      <a id="dev-contact-link" href={link}>
        <span id="dev-title">{title}</span>
      </a>
    </div>
  );
};
